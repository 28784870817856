const getBaseState = () => ({
  navigateToPoint: null,
  isPositionValid: false
})

export default {
  namespaced: true,
  state: getBaseState(),
  mutations: {
    clear (state) {
      const baseState = getBaseState()
      for (const [key, value] of Object.entries(baseState)) state[key] = value
    },
    update (state, newSettings = {}) {
      for (const [key, value] of Object.entries(newSettings)) state[key] = value
    }
  }
}
