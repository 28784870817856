import Axios from 'axios'

// const baseURL = 'https://wagner.krknet.de/api/'
const baseURL = import.meta.env.DEV ? 'http://localhost:8080/api/' : 'https://wagner.krknet.de/api/'

export default class API {
  constructor ({ username, password } = {}) {
    this.isAuthenticated = false
    this.axios = Axios.create({ baseURL, headers: { 'x-user': username, 'x-pass': password } })
  }

  getPermissions (username, password) {
    const url = `${baseURL}auth/`
    return Axios
      .get(url, { headers: { 'x-user': username, 'x-pass': password }, timeout: 5000 })
      .then(res => res.data)
      .catch(() => false)
  }

  setAuthentication (username, password) {
    this.axios = Axios.create({ baseURL, headers: { 'x-user': username, 'x-pass': password } })
    this.isAuthenticated = true
  }

  loadTask (taskID) {
    return this.axios
      .get(`/tasks/${taskID}/`)
      .then(res => ({ isSuccess: true, result: res.data }))
      .catch(err => ({ isSuccess: false, ...err.response.data }))
  }

  loadReport (taskID, optionID) {
    return this.axios
      .get(`/tasks/${taskID}/options/${optionID}/report/`)
      .then(res => ({ isSuccess: true, result: res.data }))
      .catch(err => ({ isSuccess: false, ...err.response.data }))
  }

  loadOptions (taskID) {
    return this.axios
      .get(`/tasks/${taskID}/options/`)
      .then(res => ({ isSuccess: true, result: res.data }))
      .catch(err => ({ isSuccess: false, ...err.response.data }))
  }

  callOptionFunction (taskID, optionID, parameters) {
    return this.axios
      .get(`/tasks/${taskID}/options/${optionID}/functions/${parameters.functionName}/`, { params: parameters })
      .then(res => ({ isSuccess: true, result: res.data }))
      .catch(err => ({ isSuccess: false, ...err.response.data }))
  }

  pushPoints (taskID, points) {
    return this.axios
      .put(`/tasks/${taskID}/points/`, { points })
      .then(res => ({ isSuccess: true, result: res.data }))
      .catch(err => ({ isSuccess: false, ...err.response.data }))
  }

  removeOption (taskID, optionID) {
    return this.axios
      .delete(`/tasks/${taskID}/options/${optionID}/`)
      .then(res => ({ isSuccess: true, result: res.data }))
      .catch(err => ({ isSuccess: false, ...err.response.data }))
  }

  pushOption (taskID, option) {
    return this.axios
      .put(`/tasks/${taskID}/options/${option.OptionID}/`, { option })
      .then(res => ({ isSuccess: true, result: res.data }))
      .catch(err => ({ isSuccess: false, ...err.response.data }))
  }

  getOptionNewID (taskID) {
    return this.axios
      .get(`/tasks/${taskID}/option/`)
      .then(res => res.data.id)
  }

  checkConnectivity () {
    return this.axios
      .head('/', { timeout: 6000 })
      .then(() => true)
      .catch(() => false)
  }
}
