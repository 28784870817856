import { shapeDefs, transformOption2Local, transformLocal2Option } from '@/environment'

export default {
  namespaced: true,
  state: {
    authentication: {
      username: null,
      password: null
    },
    permissions: {},
    taskID: 8,
    task: null,
    points: [],
    options: {},
    knownDevices: {}
  },
  mutations: {
    setKnownDevices (state, knownDevices) {
      state.knownDevices = knownDevices
    },
    setPermissions (state, permissions) {
      state.permissions = permissions
    },
    setAuthentication (state, { username, password }) {
      state.authentication = { username, password }
    },
    setTaskID (state, taskID) {
      state.taskID = `${taskID}`
    },
    setTask (state, task) {
      state.task = task
    },
    setPoints (state, newPoints) {
      state.points = newPoints
    },
    setOptions (state, options) {
      if (Array.isArray(options)) {
        state.options = Object.fromEntries(options.map(d => [Number(d.OptionID), d]))
      } else {
        state.options = options
      }
    },
    setOption (state, option) {
      state.options[Number(option.OptionID)] = option
    },
    cleanUpOptions (state) {
      const options = Object.values(state.options)
        .filter(d => shapeDefs[d.shape].checkValidity(d.referencePoints))
        .map(d => [d.OptionID, d])

      state.options = Object.fromEntries(options)
    },
    removeOption (state, optionID) {
      const options = Object.values(state.options)
        .filter(d => d.OptionID !== optionID)
        .map(d => [d.OptionID, d])

      state.options = Object.fromEntries(options)
    }
  },
  actions: {
    async loadInitalTask ({ commit, state, dispatch }, { router, taskID, doForce = false }) {
      if (taskID) commit('setTaskID', taskID)

      if (!doForce && state.task && state.taskID === state.task.ID) return true

      router.push('/loading/')

      const taskWrapper = await this.$api.loadTask(state.taskID)
      if (!taskWrapper.isSuccess) {
        router.push('/login/')
        return taskWrapper.message
      }

      dispatch('getPermissions')

      const { task, points, options } = taskWrapper.result
      commit('setTask', task)
      commit('setPoints', points)
      commit('setOptions', options.map(transformOption2Local))
      router.push(options.length ? '/task/' : '/task/points/map/')
    },
    async loadTask ({ commit, state }) {
      const taskWrapper = await this.$api.loadTask(state.taskID)
      if (!taskWrapper.isSuccess) {
        console.log(taskWrapper.message)
        return taskWrapper.message
      }

      const { task } = taskWrapper.result
      commit('setTask', task)
    },
    loadReport ({ state }, optionID) {
      return this.$api.loadReport(state.taskID, optionID)
    },
    removeOption ({ state, commit }, optionID) {
      return this.$api
        .removeOption(state.taskID, optionID)
        .then(res => {
          if (res.isSuccess) commit('removeOption', optionID)
          return res
        })
    },
    pushOption ({ state, commit }, optionID) {
      const option = state.options[optionID]
      return this.$api
        .pushOption(state.taskID, transformLocal2Option(option))
        .then(res => {
          if (res.isSuccess && res.result) commit('setOption', transformOption2Local(res.result))
          return res
        })
    },
    getOptionNewID ({ state }) {
      return this.$api.getOptionNewID(state.taskID)
    },
    pushPoints ({ state }) {
      return this.$api.pushPoints(state.taskID, state.points)
    },
    callOptionFunction ({ state, commit }, params) {
      return this.$api
        .callOptionFunction(state.taskID, params.optionID, params)
        .then(res => {
          if (res.isSuccess && res.result) commit('setOption', transformOption2Local(res.result))
          return res
        })
    },
    loadOptions ({ state, commit }) {
      return this.$api
        .loadOptions(state.taskID)
        .then(res => {
          if (res.isSuccess) commit('setOptions', res.result.map(transformOption2Local))
        })
    },
    getPermissions ({ state, commit }) {
      return this.$api
        .getPermissions(state.username, state.password)
        .then(permissions => {
          if (!permissions) return false

          commit('setPermissions', permissions)
          return permissions
        })
    },
    authenticate ({ commit }, { username, password }) {
      return this.$api
        .getPermissions(username, password)
        .then(permissions => {
          if (!permissions) return false

          commit('setAuthentication', { username, password })
          commit('setPermissions', permissions)
          this.$api.setAuthentication(username, password)

          return true
        })
    }
  }
}
