<template>
  <div
    v-if="built"
    class="absolute top-2 left-1/2 z-30 py-1 px-2 text-sm text-white rounded transform -translate-x-1/2 pointer-events-none bg-orange-600"
    v-text="built"
  />
  <k-app v-if="isTaskSet" theme="material" :safeAreas="false" :dark="false">
    <router-view />
  </k-app>
</template>

<script>
  import { kApp } from 'konsta/vue'
  import { App } from '@capacitor/app'

  export default {
    name: 'App',
    components: { kApp },
    data () {
      return {
        isTaskSet: false,
        built: import.meta.env.VITE_BUILD_DATE
      }
    },
    created () {
      // Load Locale
      this.$i18n.locale = this.$store.state.settings.locale

      App.addListener('backButton', () => { window.history.back() })

      App.addListener('appUrlOpen', e => {
        if (!e.url.startsWith('wms://customerboard.wagner-machines.online/tasks/')) return
        const taskID = e.url.split('/').filter(Boolean).pop()
        return this.$store
          .dispatch('environment/loadInitalTask', { router: this.$router, taskID })
          .then(() => {
            this.isTaskSet = true
          })
      })

      window.simulateOpen = taskID => this.$store.dispatch('environment/loadInitalTask', { router: this.$router, taskID, doForce: true })

      const url = new URL(window.location)
      const searchParamsTaskID = url.searchParams.get('taskID')
      if (searchParamsTaskID) {
        return window
          .simulateOpen(searchParamsTaskID)
          .then(() => {
            url.searchParams.delete('taskID')
            window.location.replace(url.toString())
          })
      }

      this.isTaskSet = true
    }
  }
</script>
