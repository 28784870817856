import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import * as Sentry from '@sentry/vue'

import App from './App.vue'
import store from './store/'
import router from './router'

import locales from './locales'

import { kButton, kLink, kPage } from 'konsta/vue'
import LoadingOverlay from './components/loading-overlay.vue'

import '@/assets/fa.5.15.min.css'
import '@/assets/styles.css'

const app = createApp(App)

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: locales
})

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://3f04bb08c8844e13b18443f6e5a63c41@sentry.krknet.de/39',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

app
  .use(router)
  .use(store)
  .use(i18n)
  .component('k-button', kButton)
  .component('k-link', kLink)
  .component('k-page', kPage)
  .component('loading-overlay', LoadingOverlay)
  .mount('#app')
