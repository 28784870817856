import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', redirect: () => Object.keys(store.state.environment.options).length ? '/task/' : '/task/points/map/' },
    {
      path: '/login/',
      component: () => import('@/views/login/index.vue')
    },
    {
      path: '/loading/',
      component: () => import('@/views/loading/index.vue')
    },
    {
      path: '/task/',
      component: () => import('@/views/task/overview/index.vue')
    },
    {
      path: '/settings/',
      component: () => import('@/views/settings/index.vue')
    },
    {
      path: '/task/points/',
      component: () => import('@/views/task/points/index.vue'),
      children: [
        {
          path: 'map',
          component: () => import('@/views/task/points/map/index.vue')
        },
        {
          path: 'list',
          component: () => import('@/views/task/points/list/index.vue')
        }
      ]
    },
    {
      path: '/task/options/:optionID/',
      component: () => import('@/views/task/options/index.vue'),
      props: true,
      children: [
        {
          path: 'shape',
          component: () => import('@/views/task/options/shape/index.vue'),
          meta: { isTabbarHidden: true }
        },
        {
          path: 'shape_attributes',
          component: () => import('@/views/task/options/shape_attributes/index.vue')
        },
        {
          path: 'general',
          component: () => import('@/views/task/options/general/index.vue')
        },
        {
          path: 'plant_spacing',
          component: () => import('@/views/task/options/plant_spacing/index.vue')
        },
        {
          path: 'row_spacing',
          component: () => import('@/views/task/options/row_spacing/index.vue')
        },
        {
          path: 'material',
          component: () => import('@/views/task/options/material/index.vue')
        },
        {
          path: 'report',
          component: () => import('@/views/task/options/report/index.vue')
        }
      ]
    }
  ]
})

router.beforeEach((to, from) => {
  // Not Authenticated
  if (!store.state.environment.authentication.username) {
    if (to.path === '/login/') return true
    if (from.path === '/login/') return false
    return '/login/'
  }

  // No Task selected
  // ToDo

  return true
})

export default router
