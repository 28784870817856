export default {
  namespaced: true,
  state: {
    locale: 'de',
    isMapPinned: false,
    mapStyle: 'default',
    heightDiff: 0
  },
  mutations: {
    update (state, newSettings = {}) {
      for (const [key, value] of Object.entries(newSettings)) state[key] = value
    }
  }
}
