export const fieldPointTypes = Object.freeze({
  border: {
    color: '251, 191, 36'
  },
  auxiliary: {
    color: '37, 99, 235'
  },
  inactive: {
    color: '156, 163, 175',
    isOnlyEditable: true
  }
})

export const cutoutPointTypes = Object.freeze({
  cutout: {
    color: '220, 38, 38'
  },
  inactive: {
    color: '156, 163, 175',
    isOnlyEditable: true
  }
})

export const pointTypes = Object.freeze({
  ...fieldPointTypes,
  ...cutoutPointTypes
})

export const shapeDefs = Object.freeze({
  rectangle: {
    id: 0,
    points: 2,
    checkValidity: points => new Array(2).fill(null).map((_, i) => points[i]).every(Boolean)
  },
  trapezoid: {
    id: 2,
    points: 4,
    checkValidity: points => new Array(4).fill(null).map((_, i) => points[i]).every(Boolean)
  },
  skew: {
    id: 1,
    points: 3,
    checkValidity: points => new Array(3).fill(null).map((_, i) => points[i]).every(Boolean)
  },
  border: {
    id: 4,
    points: 2,
    // optional: 1, // Temporarly Deactivated
    checkValidity: points => new Array(2).fill(null).map((_, i) => points[i]).every(Boolean)
  }
  // drive: {
  //   id: 5,
  //   points: 2
  // },
  // kink: {
  //   id: 3,
  //   points: 2
  // }
})

export const availableShapes = Object.freeze(Object.keys(shapeDefs))

export const getOptionAttributes = () => Object.freeze({
  Pflanzform: 1,
  Name: '',

  VerschiebungPflanze: false,
  VerschiebungPflanzeX: 5,
  VerschiebungPflanzeY: 0,
  Verbund: false,
  AusrichtungReiheRefExtern: 2,
  QuerzeilungRechtwinklig: true,
  AusrichtungRechtwinklig: 0,
  AusrichtungRefLinie: 0,
  VermittelnRechwinklig: false,
  QuerzeilungRefLinie: 0,
  RefLinieP1: 0, // null,
  RefLinieP2: 0, // null,
  RefLinieHP: 0, // null,
  AnzPflanzenKorrektur: 1,
  GrundlinieGemeinsam: true,
  GrundlinieGemeinsamAuswahl: 0,
  Hilfspunkt1: 0, // null,
  Hilfspunkt2: 0, // null,
  GrundlinieGetrennt1: 0,
  GrundlinieGetrennt2: 0,

  GrenzabstandGleichHalberReihenabstand: true,
  GrenzabstandGleich: true,
  Grenzabstand: 1000,
  Grenzabstand1: 1000,
  Grenzabstand2: 1000,
  VorgewendeGleich: true,
  Vorgewende: 2000,
  Vorgewende1: 2000,
  Vorgewende2: 2000,
  MinReihenLaenge: 5,
  GrenzabstaendeAusfuehrlich: false,
  Grenzabstaende: [],

  Pflanzabstand: 1000,
  PflanzabstandNachSteigung: 0,
  ArtPflanzabstand: 0,
  AnzPflanzenZwischenRef1Ref2: 0,

  Reihenabstand: 2000,
  AnzReihen: 0,

  AnzEndpfostenReihe: 2,
  AnzErdankerReihe: 2,
  AnzPflanzenZwischen2Pfosten: 5,
  PositionPfosten: 500,
  AnzDraehteDuenn: 4,
  AnzDraehteDick: 2,
  AnzAnkerdraehteReihe: 2,
  Staebe: true,
  Hochstamm: false,
  Bewaesserungsschlauch: false
})

const transformShape2Pflanzform = shape => shapeDefs[shape].id
export const transformPflanzform2Shape = Pflanzform => Object.entries(shapeDefs).find(([key, def]) => def.id === Number(Pflanzform))[0]

export const transformOption2Local = ({
  Rechteck = {},
  Schraege = {},
  Trapez = {},
  Knick = {},
  Begrenzungslinie = {},
  Fahrgasse = {},
  RefPunkte = [],
  Pflanzform = 1,
  ...rest
} = {}) => ({
  ...rest,
  ...Rechteck,
  ...Schraege,
  ...Trapez,
  ...Knick,
  ...Begrenzungslinie,
  ...Fahrgasse,
  referencePoints: RefPunkte,
  shape: transformPflanzform2Shape(Pflanzform)
})

export const transformLocal2Option = local => {
  // console.log('transformLocal2Option', local.Option)
  const defaults = getOptionAttributes()
  const fillFallback = keys => Object.fromEntries(keys.map(d => [d, local[d] === undefined ? defaults[d] : local[d]]))
  const keysToRemove = [
    'referencePoints',
    'shape',
    'VerschiebungPflanze',
    'VerschiebungPflanzeX',
    'VerschiebungPflanzeY',
    'Verbund',
    'AusrichtungReiheRefExtern',
    'Name',
    'QuerzeilungRechtwinklig',
    'AusrichtungRechtwinklig',
    'AusrichtungRefLinie',
    'VermittelnRechtwinklig',
    'QuerzeilungRefLinie',
    'RefLinieP1',
    'RefLinieP2',
    'RefLinieHP',
    'AnzPflanzenKorrektur',
    'AusrichtungReiheRefExtern',
    'GrundlinieGemeinsam',
    'GrundlineGemeinsamAuswahl',
    'GrundlinieGetrennt1',
    'GrundlinieGetrennt2',
    'Hilfspunkt1',
    'Hilfspunkt2'
  ]

  return {
    ...Object.fromEntries(Object.entries(local).filter(([key]) => !keysToRemove.includes(key))),

    RefPunkte: local.referencePoints,
    Pflanzform: transformShape2Pflanzform(local.shape),

    Rechteck: fillFallback(['VerschiebungPflanze', 'VerschiebungPflanzeX', 'VerschiebungPflanzeY', 'Verbund', 'AusrichtungReiheRefExtern', 'Name']),
    Schraege: fillFallback(['VerschiebungPflanze', 'VerschiebungPflanzeX', 'VerschiebungPflanzeY', 'Verbund', 'AusrichtungReiheRefExtern', 'Name']),
    Trapez: fillFallback(['QuerzeilungRechtwinklig', 'AusrichtungRechtwinklig', 'AusrichtungRefLinie', 'VermittelnRechtwinklig', 'Name']),
    Knick: fillFallback(['QuerzeilungRefLinie', 'RefLinieP1', 'RefLinieP2', 'RefLinieHP']),
    Begrenzungslinie: fillFallback(['AnzPflanzenKorrektur', 'AusrichtungReiheRefExtern']),
    Fahrgasse: fillFallback(['GrundlinieGemeinsam', 'GrundlineGemeinsamAuswahl', 'GrundlinieGetrennt1', 'GrundlinieGetrennt2', 'Hilfspunkt1', 'Hilfspunkt2'])
  }
}

export const mapStyles = {
  default: {
    label: 'Karte',
    styleName: 'Wine II',
    icon: 'fa-drafting-compass',
    url: 'mapbox://styles/krknetwork/ck9o614555d801iqo6gg95btk'
  },
  satellite: {
    label: 'Satellit',
    styleName: 'Wine III',
    icon: 'fa-satellite',
    url: 'mapbox://styles/krknetwork/ckbnjcfg42fn21ipu2prql54b'
  }
}
