import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import API from './api'

import pointPageStore from './points'
import settingsStore from './settings'
import environmentStore from './environment'

const persistSettings = new VuexPersistence({ key: 'WMS-Settings', modules: ['settings'] })
const persistEnvironment = new VuexPersistence({ key: 'WMS-Environment', modules: ['environment'] })

const store = createStore({
  state () {
    return {
    }
  },
  modules: {
    pointsPage: pointPageStore,
    settings: settingsStore,
    environment: environmentStore
  },
  plugins: [persistSettings.plugin, persistEnvironment.plugin]
})

store.$api = new API(store.state.environment.authentication)
store.$rtk = null

export default store
