<template>
  <div class="flex fixed inset-0 z-50 flex-col justify-center items-center backdrop-blur-sm bg-white/70">
    <div class="flex justify-center items-center p-14 w-32 h-32 text-blue-600 glow-white spinner">
      <!-- <v-logo class="glow-white" /> -->
    </div>
    <p
      class="mt-2 text-2xl text-gray-600 glow-white"
      v-text="$t('loading')"
    />
  </div>
</template>

<script>
  export default {
    name: 'LoadingOverlay'
  }
</script>
